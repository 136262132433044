import * as React from 'react';

import logo from './logo.svg';
import './App.css';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import NativeSelect from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const apps = [
  {
    "stage": "dev",
    "title": "DocGen Webapp",
    "url": "https://development.docgen.trelent.net",
    "desc": "Latest dev build of DocGen - Trelent's user web portal."
  },
  {
    "stage": "prod",
    "title": "DocGen Webapp",
    "url": "https://docgen.trelent.net",
    "desc": "Live version of DocGen - Trelent's user web portal."
  },
  {
    "stage": "dev",
    "title": "Trelent Website",
    "url": "https://development.trelent.net",
    "desc": "Latest dev build for Trelent's website."
  },
  {
    "stage": "prod",
    "title": "Trelent Website",
    "url": "https://trelent.net",
    "desc": "Live version of Trelent's website."
  },
  {
    "stage": "dev",
    "title": "User Management App",
    "url": "https://development.management.internal.trelent.net",
    "desc": "Latest dev build of Trelent's user management app."
  },
  {
    "stage": "prod",
    "title": "User Management App",
    "url": "https://management.internal.trelent.net",
    "desc": "Live version of Trelent's user management app."
  }
];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8} lg={4}>
            <img src={logo} className="App-logo" alt="logo" />
          </Grid>
          <Grid item xs={12}>
            <h3>
              Where would you like to go?
            </h3>
          </Grid>
        </Grid>
      </header>
      <section className="Navigation">
        <AppList apps={apps} />
      </section>
    </div>
  );
}

function AppList(props) {
  const { apps } = props;

  const [stage, setStage] = React.useState(['prod']);
  const [search, setSearch] = React.useState('');


  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setStage(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSearchChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
  };

  return (
    <div>
      <Grid container spacing={3} sx={{width: "70%", marginLeft: "15%"}}>
        <Grid item xs={12} md={4} lg={4}>
          <FormControl fullWidth>
            <InputLabel id="stage-select-label">Stage</InputLabel>
            <NativeSelect
              labelId="stage-select-label"
              id="stage-select"
              value={stage}
              label="Stage"
              multiple={true}
              onChange={handleSelectChange}
            >
              <MenuItem value={"dev"} key={"dev"}>Development</MenuItem>
              <MenuItem value={"prod"} key={"prod"}>Production (Live)</MenuItem>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={0} md={0} lg={2}></Grid>
        <Grid item xs={12} md={8} lg={6}>
          <TextField id="search-textfield" fullWidth label="Search" variant="outlined" onChange={handleSearchChange} />
        </Grid>
      </Grid>
      <Divider sx={{marginTop: "1rem", marginBottom: "1rem"}} />
      <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{width: "70%", marginLeft: "15%"}}>
        {
          apps.filter((app) => {
            return stage.includes(app.stage) && 
            (
              app.title.toLowerCase().includes(search.toLowerCase())
              ||
              app.desc.toLowerCase().includes(search.toLowerCase())
            );
          }).map((app) => {
            return (
              <Grid item xs={12} md={8} lg={6}>
                <SiteCard title={app.title} description={app.desc} buttonText="Go to site" buttonLink={app.url}/>
              </Grid>
            );
          })
        }
      </Grid>
    </div>
  );
}

function SiteCard(props) {
  const { title, description, buttonText, buttonLink } = props;
  return (
    <Card sx={{ background: "#2a2a2a", boxShadow: 3, textAlign: 'left' }}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => {buttonClick(buttonLink)}} size="large" variant="outlined">{buttonText}</Button>
      </CardActions>
    </Card>
  );
}

function buttonClick(link) {
  window.location.href = link;
}

export default App;
